:root {
  --yellow: #fccd35;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --dark-grey: #2b2b2b;
  --black: #000000;
}
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap'); 

